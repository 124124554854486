<template>
  <div>
    <Navigation />
    <v-container>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row>
            <v-col>
              <h1>Cobranza Vida + Ahorro</h1>
            </v-col>
          </v-row>
          <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
          <v-row class="mb-15" style="padding-left: 30px">
            <v-col cols="3">
              <h3>Selecciona el rango de fechas</h3>
              <v-date-picker
                style="width: 100%"
                v-model="dates"
                range
                locale="es"
              ></v-date-picker>
              <v-btn
                rounded
                color="primary"
                style="width: 100%"
                dark
                @click="getReporte()"
                >Consultar</v-btn
              >
              <v-btn
                rounded
                color="primary"
                style="width:100%;margin-top:20px;"
                :disabled="bloquear"
                ><download-csv
                  :data="reportDownload"
                  name="Informe_bradescard_vida_ahorro.csv"
                >
                  Descargar Información
                </download-csv></v-btn
              >
            </v-col>
            <v-col cols="9">
              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-data-table
                :headers="columns"
                :items="reporteData"
                item-key="name"
                class="elevation-1"
                no-data-text="Sin datos para mostrar"
              >
                <template v-slot:item.vigenciaInicio="{ item }">
                  <div>
                    {{ moment(item.vigenciaInicio).format("DD/MM/YYYY") }}
                  </div>
                </template>
                <template v-slot:item.vigenciaFin="{ item }">
                  <div>
                    {{ moment(item.vigenciaFin).format("DD/MM/YYYY") }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";

import moment from "moment-timezone";
// ADMIN FINANZAS y DIRECTOR

export default {
  components: {
    Navigation,
  },

  data() {
    return {
      moment,
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      bloquear: true,
      reporteData: [],
      reportDownload: [],

      columns: [
        { text: "No. Póliza", value: "poliza_aseguradora" },
        { text: "Certificado Bradescard", value: "certificado_bradescard" },
        { text: "Estatus de la poliza", value: "statusCuota" },
        { text: "Cliente", value: "nombreCliente" },
        { text: "Agente", value: "nombreEjecutivo" },
        { text: "Nombre de producto", value: "producto_aseguradora" },
        { text: "Ramo", value: "ramo" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Nueva / Renovación", value: "tipo_Poliza" },
        { text: "Inicio de vigencia", value: "vigenciaInicio" },
        { text: "Fin de vigencia", value: "vigenciaFin" },
      ],

      columnsReporte: [
        { text: "Póliza Aseguradora", value: "poliza_aseguradora" },
        { text: "Certificado Aseguradora", value: "poliza" },
        { text: "Estatus Póliza", value: "statusEmision" },
        { text: "Tipo Póliza", value: "tipo_Poliza" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Producto", value: "producto_aseguradora" },
        { text: "Etiqueta", value: "etiqueta_aseguradora" },
        { text: "Inicio de vigencia", value: "vigenciaInicio" },
        { text: "Fin de vigencia", value: "vigenciaFin" },
        { text: "Nueva/Renovación", value: "tipo_Poliza" },
        { text: "Periodicidad", value: "pago_id" },
        { text: "Pago Mensual", value: "primer_Pago" },
        { text: "No. de Cuota", value: "orden" },
        { text: "Estatus de la Cuota", value: "statusCuota" },
        { text: "Fecha de Pago Acordada", value: "fPagoAcordado" },
        { text: "Fecha de Pago Aplicado", value: "fecha_pago" },
        { text: "Monto de Pago Aplicado", value: "valorAPagar" },
        { text: "Ejecutivo", value: "nombreEjecutivo" },
        { text: "Cliente", value: "nombreCliente" },
        { text: "RFC Cliente", value: "emisionRFC" },
        { text: "Certificado Bradescard", value: "certificado_bradescard" },
        { text: "Fecha Venta", value: "fecha_emision" },
        { text: "ID Segurify", value: "emisionId" },
        { text: "Archivo", value: "archivo" },
        { text: "Fecha Archivo", value: "fecha_archivo" },
        { text: "Fecha Movimiento", value: "fecha_movimiento" },
      ],

      statusEmisiones: {
        0: "Nueva",
        1: "Emitida Pendiente de Pago",
        2: "Pagada Parcial",
        3: "Pagada Total",
        4: "Renovada",
        5: "Finalizada",
        6: "Cancelada",
        8: "Emitido el certificado",
      },

      periodicidadValues: {
        "1": "Anual",
        "2": "Semestral",
        "3": "Trimestral",
        "4": "Mensual",
      },
    };
  },

  methods: {
    getReporte() {
      this.reportDownload = [];
      this.reporteData = [];
      this.loading = true;
      const reporteUrl = "/v1/reporte/cuotas/metlife";
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD 00:00:00");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD hh:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD 00:00:00");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD hh:mm:ss");
        }
      }
      const body = { fechaInicio: inicioUTC, fechaFin: finUTC };
      mainAxios.post(reporteUrl, body, config).then(({ data }) => {
        data.forEach((element) => {
          let payloadDataTable = {};
          let payloadDownload = {};
          this.columns.forEach((column) => {
            payloadDataTable[column.value] =
              element[column.value] || "No disponible";
          });
          this.columnsReporte.forEach((column) => {
            if (column.value == "pago_id")
              payloadDownload[column.text] =
                this.periodicidadValues[element[column.value]] ||
                "No disponible";
            else if (column.value == "statusEmision")
              payloadDownload[column.text] =
                this.statusEmisiones[element[column.value]] || "No disponible";
            else
              payloadDownload[column.text] =
                element[column.value] || "No disponible";
          });
          this.reporteData.push(payloadDataTable);
          this.reportDownload.push(payloadDownload);
        });
      });

      this.bloquear = false;
      this.loading = false;
    },
  },
};
</script>
